<template>
  <div>
    <div class="row justify-content-between">
      <div class="col">
        <div class="card-title mb-0">
          <h2>
            <strong>{{ param.name }}</strong>
          </h2>
        </div>
      </div>
      <div>
        <div class="col text-right" v-if="param.showButtons">
          <div class="smallFont">
            <router-link :to="{ path: url }" class="formBtn">
              <button
                v-b-popover.hover.bottomright="'Add using form'"
                class="btn btn-light btn-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="grey"
                  class="bi bi-plus-circle iconShadow"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </button>
            </router-link>
          </div>
        </div>
        <div class="smallFont" v-if="param.addRowRef">
          <div>
            <button
              v-b-popover.hover.bottomright="'Add on table'"
              class="btn btn-light btn-sm"
              @click="addNewRowByRef(param.addRowRef)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="grey"
                class="bi bi-plus-circle iconShadow"
                viewBox="0 0 16 16">
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "ShadowCardHeader",
  components: {},
  props: {
    param: {
      type: Object,
    },
  },
  data() {
    return {
      id: 1,
    };
  },

  methods: {
    addNewRow() {
      this.id = uuidv4();

      this.$store.commit("Add_acountItem", this.id);
    },
    addNewRowByRef(ref) {
      this.$parent.$parent.$refs[ref].addRow();
      //this.$refs.positionsForm.addRow()
      //this.$refs.childComponent.setValue
    },
  },
  computed: {
    url() {
      return this.$router.currentRoute.path + "/addnew"; //deberia ser MiURL + /addnew
    },
  },
};
</script>
<style scoped>
.formBtn {
  align-self: center;
}
</style>
