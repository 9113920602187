<template>
  <div>
    <FormulateForm
      class="mb-3 custom-outer"
      name="positionClosingForm"
      @submit="handleSubmit">
      <FormulateInput
        type="select"
        label="Portfolio"
        :options="portfolioList"
        v-model="formSelectedPortfolio"
        :placeholder="placeholderPortfolio"
        validation="required"
        :disabled="isDisabled"
        :input-class="['select-portfolio']"></FormulateInput>

      <FormulateInput
        type="select"
        label="Asset"
        :options="assetOptions"
        v-model="formSelectedAsset"
        :placeholder="placeholderAsset"
        @change="handleAssetSelection"
        :disabled="isDisabled"
        :input-class="['select-asset']" />

      <FormulateInput
        type="select"
        label="Position"
        :options="positionsOptions"
        v-model="formSelectedPositions"
        placeholder="Select a position"
        :disabled="isDisabled" />

      <FormulateInput
        type="text"
        v-model="principalFieldSum"
        label="Principal"
        validation="required|number|^not:0"
        :disabled="isDisabled"
        :validation-messages="{
          not: 'Error, “0” is not allowed.',
        }"
        :input-class="['input-amount']" />

      <FormulateInput
        type="text"
        v-model="selectedSecurityLastPrice"
        label="Price"
        validation="required|number"
        :disabled="isDisabled"
        :input-class="['input-amount']" />

      <FormulateInput
        type="text"
        v-model="selectedCurrencyLastPrice"
        label="FX Rate"
        validation="required|number"
        :disabled="isDisabled"
        :input-class="['input-amount']" />
    </FormulateForm>
    <div class="row justify-content-between">
      <BackButton />
      <div class="col-8 text-right">
        <ResetButton @reset-form="resetForm" />
        <SubmitButton
          :ShowSubmitButton="isDisabled"
          :ShowOverlay="isOverlayDisabled"
          @submit-form="handleSubmitBtnClick" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPortfolioDetail,
  getSecurityLastPrice,
  getCurrencyLastPrice,
  closePortfolioPosition,
} from "@/services/api.js";

import BackButton from "@/components/buttons/backButton.vue";
import ResetButton from "@/components/buttons/resetButton.vue";
import SubmitButton from "@/components/buttons/submitButton.vue";

export default {
  name: "positionClosingForm",
  components: {
    BackButton,
    ResetButton,
    SubmitButton,
  },
  data() {
    return {
      formSelectedPortfolio: "",
      formSelectedAsset: "",
      formSelectedPositions: [],
      portfolioOptions: [],
      assetOptions: [],
      positionsOptions: [],
      selectedSecurityLastPrice: "",
      selectedCurrencyLastPrice: "",
      principalFieldSum: 0,
      isDisabled: false,
      isOverlayDisabled: false,
    };
  },
  mounted() {
    this.formSelectedPortfolio = this.storeSelectedPortfolio;
  },
  watch: {
    formSelectedPortfolio: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getPortfolioDetail();
        }
      },
    },
    formSelectedAsset: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.handleAssetSelection();
        }
      },
    },
    formSelectedPositions: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.updatePrincipalFieldSum();
        }
      },
    },
  },
  computed: {
    selectedPortfolio() {
      return this.$store.state.selectedPortfolio;
    },
    // Computed para generar el texto del marcador de posición
    placeholderPortfolio() {
      return this.portfolioList.length > 0
        ? "Select a Portfolio"
        : "No options avaliable";
    },
    placeholderAsset() {
      return this.assetOptions.length > 0
        ? "Select an asset"
        : "No options avaliable";
    },
    portfolioList() {
      return this.$store.state.portfolioList.map(
        (portfolio) => portfolio.portfolio_id
      );
    },
    storeSelectedPortfolio() {
      return this.$store.state.closingFormSelectedPortfolio;
    },
    storeSelectedAsset() {
      return this.$store.state.closingFormSelectedAsset;
    },
    storeSelectedPosition() {
      return this.$store.state.closingFormSelectedPosition;
    },
    selectedAssetIdentifier() {
      const foundOption = this.assetOptions.find(
        (item) => item.value === this.formSelectedAsset
      );
      return foundOption?.attrs.asset_identifier ?? null;
    },
    selectedAssetCurrency() {
      const foundOption = this.assetOptions.find(
        (item) => item.value === this.formSelectedAsset
      );
      return foundOption?.attrs.currency ?? null;
    },
  },
  methods: {
    getPortfolioDetail() {
      this.assetOptions = [];
      this.positionsOptions = [];
      this.formSelectedAsset = "";
      this.formSelectedPositions = [];
      this.selectedSecurityLastPrice = "";
      this.selectedCurrencyLastPrice = "";
      getPortfolioDetail(this.formSelectedPortfolio).then((response) => {
        const responseData = response.data.data;

        this.assetOptions = this.buildAssetOptions(responseData);

        const storeSelectedAssetID = this.storeSelectedAsset;

        const foundAssetProps = this.assetOptions.find((option) => {
          if (storeSelectedAssetID.length > 0) {
            return option.value === storeSelectedAssetID;
          } else {
            return option.value;
          }
        });

        if (storeSelectedAssetID.length > 0) {
          this.formSelectedAsset = foundAssetProps.value;
        } else {
          this.formSelectedAsset = foundAssetProps;
        }
      });
    },
    handleAssetSelection() {
      this.positionsOptions = [];
      this.formSelectedPositions = [];

      this.positionsOptions = this.buildPositionsOptions();

      if (this.formSelectedAsset) {
        this.getSecurityLastPrice();
        this.getCurrencyLastPrice();
      }

      // Busco la position guardada en el store entre las opciones del input de positions.
      const foundPosition = this.positionsOptions.find((option) => {
        if (this.storeSelectedPosition.length > 0) {
          return option.value === this.storeSelectedPosition;
        } else {
          return option.value;
        }
      });
      // Si se encontró, la selecciono.
      if (foundPosition) {
        this.formSelectedPositions = foundPosition.value;
      }
    },
    updatePrincipalFieldSum() {
      let sum = 0;
      for (const position of this.positionsOptions) {
        if (this.formSelectedPositions.includes(position.value)) {
          sum += Number(position.principal);
        }
      }
      this.principalFieldSum = sum;
    },
    getSecurityLastPrice() {
      this.selectedSecurityLastPrice = "";

      if (this.selectedAssetIdentifier) {
        getSecurityLastPrice(this.selectedAssetIdentifier).then((response) => {
          const responseData = response.data.data;
          for (const date in responseData) {
            for (const identifier in responseData[date]) {
              this.selectedSecurityLastPrice =
                responseData[date][identifier][0].closePrice;
            }
          }
        });
      }
    },
    getCurrencyLastPrice() {
      this.selectedCurrencyLastPrice = "";

      if (this.selectedAssetCurrency === "USD") {
        this.selectedCurrencyLastPrice = 1;
      } else {
        getCurrencyLastPrice(`${"USD"}${this.selectedAssetCurrency}`).then(
          (response) => {
            const responseData = response.data.data;
            for (const date in responseData) {
              for (const identifier in responseData[date]) {
                this.selectedCurrencyLastPrice =
                  responseData[date][identifier][0].closePrice;
              }
            }
          }
        );
      }
    },
    buildAssetOptions(responseData) {
      let assetOptions = responseData
        .map((item) => {
          const label = `${item.Asset_Name} (${item.Asset_Identifier})`;
          const value = item.Asset_ID;

          return {
            label,
            value,
            attrs: {
              asset_id: item.Asset_ID,
              position_id: item.Position_ID,
              trade_date: item.Trade_Date,
              principal: item.Principal,
              paid_price: item.Paid_Price,
              asset_identifier: item.Asset_Identifier,
              currency: item.Currency,
            },
          };
        })
        .sort((a, b) => {
          const labelA = a.label.toUpperCase();
          const labelB = b.label.toUpperCase();
          return labelA.localeCompare(labelB);
        });

      return assetOptions;
    },

    buildPositionsOptions() {
      const formSelectedAsset = this.formSelectedAsset;
      return this.assetOptions
        .filter((option) => option.attrs.asset_id === formSelectedAsset)
        .map((option) => {
          const { position_id, trade_date, principal, paid_price } =
            option.attrs;

          const label = `${position_id} - ${trade_date} - ${principal} - ${paid_price}`;

          return {
            value: position_id,
            label,
            principal,
          };
        });
    },
    handleSubmitBtnClick() {
      this.isOverlayDisabled = true;
      this.$formulate.submit("positionClosingForm");
    },
    handleSubmit() {
      const createObjectToPost = {
        positions: Array.of(this.formSelectedPositions).map((position) => ({
          portfolio_id: this.formSelectedPortfolio,
          asset_id: this.formSelectedAsset,
          principal: this.principalFieldSum,
          exit_date: new Date(),
          exit_price: this.selectedSecurityLastPrice,
          exit_fx_rate: this.selectedCurrencyLastPrice,
          position_id: position,
        })),
      };

      closePortfolioPosition(createObjectToPost)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            this.makeToast(
              "success",
              response.status,
              "Position closed successfully."
            );

            if (this.selectedPortfolio) {
              if (this.formSelectedPortfolio === this.selectedPortfolio) {
                this.$store.dispatch("updatePortfolioView");
              }
            }
          }
          this.isDisabled = true;
          this.isOverlayDisabled = false;
        })
        .catch((error) => {
          this.makeToast("danger", error, "Error closing position.");
        });
    },
    resetForm() {
      const initialValues = {
        formSelectedPortfolio: "",
        formSelectedAsset: "",
        formSelectedPositions: [],
      };
      this.$formulate.reset("positionClosingForm", initialValues);
      this.isDisabled = false;
    },
    makeToast(variant = null, status, text) {
      this.$bvToast.toast([text], {
        title: `Code: ${status || "default"}`,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
      });
    },
  },
};
</script>

<style>
.select-portfolio {
  min-width: 190px;
}
.select-asset {
  min-width: 190px;
}
.input-amount {
  min-width: 120px;
  text-align: right;
}

@media screen and (min-width: 576px) {
  .select-portfolio {
    width: 190px;
  }
  .select-asset {
    width: 190px;
  }
  .input-amount {
    width: 120px;
  }
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right calc(0.95em + 0.1875rem) center !important;
}
.field-border-bottom-only {
  border: none;
  border-bottom: 1px solid #ccc; /* Ajusta el color y el grosor según tus preferencias */
  border-radius: 0; /* Opcional: si deseas eliminar también el radio de los bordes */
}
.custom-outer .formulate-input {
  margin-right: 1rem;
}
</style>
