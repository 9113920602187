<template>
  <button
    type="button"
    class="btn btn-lg mr-2 px-5 rounded-0 font-weight-bold btn-secondary"
    squared
    @click="resetForm">
    Reset
  </button>
</template>

<script>
export default {
  name: "resetButton",
  methods: {
    resetForm() {
      this.$emit("reset-form");
    },
  },
};
</script>

<style>
.btn-secondary {
  color: #343a40;
  font-size: 24px;
  border-width: 0px;
  background-color: white;
}

.btn-secondary:hover {
  color: white;
  font-size: 24px;
  border-width: 0px;
  background-color: #6c757d;
}
</style>
