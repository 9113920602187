<template>
  <b-overlay :show="ShowOverlay" class="d-inline-block" spinner-small>
    <button
      squared
      class="btn btn-lg px-5 text-white rounded-0 font-weight-bold border-1 btn-primary"
      @click="handleSubmitBtnClick"
      :disabled="ShowSubmitButton">
      Submit
    </button></b-overlay
  >
</template>

<script>
export default {
  name: "submitButton",
  props: {
    ShowSubmitButton: {
      type: Boolean,
      default: false,
    },
    ShowOverlay: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSubmitBtnClick() {
      this.$emit("submit-form");
    },
  },
};
</script>

<style>
/* Primary Button */

@media screen and (max-width: 576px) {
  .px-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.btn-primary {
  font-size: 24px;
  border-color: #273a5c;
  background-color: #273a5c;
}

.btn-primary:hover {
  border-color: #6c757d;
  background-color: #6c757d;
}

.btn-primary.disabled,
.btn-primary:disabled {
  border-color: white;
  background-color: #273a5c;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: #273a5c;
  -webkit-box-shadow: 0 0 0 0.2rem white;
  box-shadow: 0 0 0 0.2rem white;
}
</style>
