<template>
  <ShadowCard
    :param="{
      name: 'Position Closing',
    }"
    ><positionClosingForm />
  </ShadowCard>
</template>

<script>
import ShadowCard from "@/presentationalComponents/ShadowCard.vue";

import positionClosingForm from "./positionClosingForm.vue";

export default {
  name: "PositionClosingView",
  components: {
    ShadowCard,
    positionClosingForm,
  },
};
</script>

<style></style>
