<template>
  <div class="card cardShadowContainer">
    <div class="card-body accountBody">
      <ShadowCardHeader v-bind="{ param }" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ShadowCardHeader from "@/presentationalComponents/ShadowCardHeader.vue";
export default {
  name: "ShadowCard",
  components: { ShadowCardHeader },
  props: {
    param: {
      type: Object,
    },
  },
};
</script>
